import { css, jsx } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import { Heading } from '../chakra/Heading'
import Div from '../Div'
import theme from '../../constants/theme'

const styles = {
  heading: isDark => css`
    font-family: ${theme.fonts.body};
    margin: auto;
    width: 88%;
    color: ${isDark ? theme.colors.text.accentedGray : theme.colors.secondary.base};
    strong {
      font-weight: ${theme.fontWeights.normal};
    }
  `,
  fontSize: ['18px', '21px'],
  marginTop: ['15px'],
}

function FeaturesDescription() {
  const { colorMode } = useColorMode()

  return (
    <Div mt={styles.marginTop}>
      <Heading
        as="h4"
        fontSize={styles.fontSize}
        block
        fontWeight="light"
        textAlign="center"
        width={styles.width}
        css={styles.heading(colorMode === 'dark')}
      >
        <strong>Save</strong> tweets, articles to read later, GitHub repositories, Stack Overflow questions, and
        bookmark all your most frequent pages or <strong>any reference materials with just 2 clicks</strong>
      </Heading>
    </Div>
  )
}

export default FeaturesDescription
