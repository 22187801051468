import { css, jsx } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import { Heading } from '../chakra/Heading'
import Div from '../Div'
import theme from '../../constants/theme'
import { useShowAccented } from '../../dataHooks'

const styles = {
  heading: css`
    font-family: ${theme.fonts.body};
    margin: auto;
    text-align: center;

    strong {
      font-weight: ${theme.fontWeights.normal};
    }
  `,
  fontSize: ['20px', '23px', '26px', '28px'],
  marginTop: ['24px'],
  width: ['88%', '90%', '80%', '80%'],
}

function Subtitle() {
  const { colorMode } = useColorMode()
  const color = useShowAccented({
    accented: colorMode === 'dark' ? theme.colors.text.accentedGray : '',
    normal: '',
  })
  return (
    <Div mt={styles.marginTop}>
      <Heading
        as="h2"
        fontSize={styles.fontSize}
        block
        fontWeight="light"
        width={styles.width}
        css={styles.heading}
        color={color}
      >
        BackToIt is the <strong>fastest</strong> and <strong>easiest</strong> way to collect, organize, track (coming soon!!) and{' '}
        <strong>bookmark</strong> any link on the web
      </Heading>
    </Div>
  )
}

export default Subtitle
