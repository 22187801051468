import { Fragment } from 'react'
import { css, jsx } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import { Box } from '../chakra/Box'
import useResponsive from '../../utils/hooks/useResponsive'
import { Bolt } from '../chakra/icons/Bolt'
import { Dollar } from '../chakra/icons/Dollar'
import { Shield } from '../chakra/icons/Shield'
import { Smile } from '../chakra/icons/Smile'
import theme from '../../constants/theme'
import Div from '../Div'
import { Text } from '../chakra/Text'
import VerticalSpace from '../VerticalSpace'
import { useShowAccented } from '../../dataHooks'

const characteristics = [
  {
    id: 1,
    icon: Bolt,
    iconSize: 7.2,
    title: 'Fastest',
    text: 'We run on the latest web technologies',
  },
  {
    id: 2,
    icon: Smile,
    iconSize: 6.5,
    title: 'Simpler',
    text: 'Bookmark any link with just two clicks',
  },
  {
    id: 3,
    icon: Dollar,
    iconSize: 6.7,
    title: 'Cheaper',
    text: 'The cheapest option there is out there',
  },
  {
    id: 4,
    icon: Shield,
    iconSize: 6.5,
    title: 'More secure',
    text: 'No ads, no spam, own your data',
  },
]

const styles = {
  item: columnType => css`
    width: 100%;
    display: flex;
    flex-direction: ${columnType};
  `,
  container: gridSeparation => css`
    display: grid;
    grid-column-gap: 1em;
    grid-row-gap: 1.3em;
    grid-template-columns: repeat(${gridSeparation}, minmax(0, 1fr));
  `,
  itemIcon: css`
    color: #fff;
  `,
  itemIconDimensions: ['30px', '38px'],
  itemTextWrapper: (textType, paddingLeft) => css`
    text-align: ${textType};
    padding-left: ${paddingLeft};
  `,
  iconWrapper: (itemIconMarginBottom, iconSizeContainer, darkMode) => css`
    border-radius: 8px;
    background: ${darkMode ? theme.colors.background.dark : theme.colors.secondary.base};
    width: ${iconSizeContainer};
    height: ${iconSizeContainer};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${itemIconMarginBottom};
    border: 2px solid rgba(73, 80, 87, 0.1);
  `,
  itemTitleFontSize: ['17px', '19px', '20px'],
  itemTextFontSize: ['14px', '15px'],
}

function Characteristics() {
  const { colorMode } = useColorMode()
  const topSpace = useResponsive(7, 8, 2)
  const columnType = useResponsive('column', 'row', 'column')
  const textType = useResponsive('center', 'left', 'center')
  const gridSeparation = useResponsive(1, 2, 4)
  const itemIconMarginBottom = useResponsive('10px', '0px', '15px')
  const subtitleAlign = useResponsive('center', 'left', 'center')
  const subtitleWidth = useResponsive('100%', '100%', '90%', '82%')
  const itemTextWrapperPl = useResponsive(0, '15px', 0)
  const subtitleMargin = useResponsive(0, 0, 'auto')
  const iconSizeContainer = useResponsive('60px', '65px', '70px')
  const subtitleColor = useShowAccented({
    accented: colorMode === 'dark' ? theme.colors.text.accentedGray : '',
    normal: '',
  })

  return (
    <Fragment>
      <VerticalSpace space={topSpace} />
      <Box css={styles.container(gridSeparation)} width={subtitleWidth} margin={subtitleMargin}>
        {characteristics.map(({ icon: ItemIcon, ...item }) => (
          <Box key={item.id} css={styles.item(columnType)}>
            <Div center>
              <div css={styles.iconWrapper(itemIconMarginBottom, iconSizeContainer, colorMode === 'dark')}>
                <ItemIcon height={styles.itemIconDimensions} width={styles.itemIconDimensions} css={styles.itemIcon} />
              </div>
            </Div>
            <Div flex column css={styles.itemTextWrapper(textType, itemTextWrapperPl)}>
              <Text fontWeight="semibold" fontSize={styles.itemTitleFontSize} color="" mb="5px">
                {item.title}
              </Text>
              <Text
                block
                fontWeight="normal"
                fontSize={styles.itemTextFontSize}
                color={subtitleColor}
                width={subtitleWidth}
                margin={subtitleMargin}
                textAlign={subtitleAlign}
              >
                {item.text}
              </Text>
            </Div>
          </Box>
        ))}
      </Box>
    </Fragment>
  )
}

export default Characteristics
