import { useEffect, useState } from 'react'
import { css, jsx, keyframes } from '@emotion/react'
import { Fade, useColorMode } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Image } from '../chakra/Image'
import Div from '../Div'
import theme from '../../constants/theme'
import dashboardBgLight from '../../assets/dashboardBgLight.webp'
import dashboardBgDark from '../../assets/dashboardBgDark.webp'
import { AspectRatio } from '../chakra/AspectRatio'
import VerticalSpace from '../VerticalSpace'
import useResponsive from '../../utils/hooks/useResponsive'
import ImgFallbackProgress from '../ImgFallbackProgress'
import { Play } from '../chakra/icons/Play'
import { Button } from '../chakra/Button'

const ModalVideoNoSSR = dynamic(() => import('react-modal-video'), { ssr: false })

const imgRadius = '0.7rem'

const screenPerspective = keyframes`
  0% {
    opacity: .72;
    transform: translate3d(0, -8vh, 0) scale3d(.85, .85, 1) rotateX(-40deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
`

const styles = {
  perspective: css`
    perspective: 1500px;
  `,
  animation: css`
    animation: ${screenPerspective} 1s linear calc(var(--scroll) * -21s) forwards paused;
    height: auto;
    opacity: 1;
    text-align: center;
    transition: all 200ms;
    width: 100%;
    transform-style: preserve-3d;
    will-change: transform, opacity;
  `,
  image: css`
    border-radius: ${imgRadius};
    object-fit: contain !important;
  `,
  container: (borderSize, isDark) => css`
    border-radius: ${imgRadius};
    box-shadow: rgba(67, 90, 111, 0.3) 0 0 1px, rgba(67, 90, 111, 0.47) 0 5px 8px -4px;
    border: ${borderSize} solid ${isDark ? theme.colors.text.accentedGray : theme.colors.accented.base};
    position: relative;
  `,
  lightRatio: isDark => css`
    display: ${isDark ? 'none' : 'block'};
  `,
  darkRatio: isDark => css`
    display: ${isDark ? 'block' : 'none'};
  `,
  videoPlay: (colorMode, playSize) => css`
    width: ${playSize};
    height: ${playSize};
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    border-radius: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      ${colorMode === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)'} 0px 4px 6px -1px,
      ${colorMode === 'light' ? 'rgba(0, 0, 0, 0.06)' : 'rgba(255, 255, 255, 0.06)'} 0px 2px 4px -1px;
    background: ${colorMode === 'light' ? 'white' : theme.colors.background.richBlack};
    cursor: pointer;
    :hover {
      transform: translate(-50%, -50%) scale(1.05);
    }
    &:focus {
      box-shadow: 0 0 0 3px ${theme.colors.primary.base};
      transition: box-shadow ${theme.transition.duration.normalSlow};
    }
    svg {
      position: relative;
      left: 2px;
    }
  `,
}

function DashboardBg() {
  const router = useRouter()
  const { asPath } = router
  const [ytVideo, setYtVideo] = useState(false)
  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'
  const topSpace = useResponsive(8, 11)
  const borderSize = useResponsive('2px', '3px')
  const playSize = useResponsive('58px', '80px')

  useEffect(() => {
    document.documentElement.style.setProperty('--scroll', 0)
    if (asPath?.includes('openVideo=true')) {
      setYtVideo(true)
    }
  }, [])

  return (
    <Div>
      <ModalVideoNoSSR
        channel="youtube"
        autoplay={1}
        isOpen={ytVideo}
        videoId="JaDnohBT8zM"
        onClose={() => setYtVideo(false)}
        showinfo={0}
        start={1}
        theme={colorMode}
        allowfullscreen
      />
      <VerticalSpace space={topSpace} />
      <div css={styles.perspective}>
        <div css={styles.animation}>
          <Div css={styles.container(borderSize, isDark)}>
            <Button reset css={styles.videoPlay(colorMode, playSize)} onClick={() => setYtVideo(true)}>
              <Play boxSize={playSize === '80px' ? 6 : 5} color="accented.base" />
            </Button>
            <Fade in={!isDark}>
              <AspectRatio maxH="560px" ratio={2880 / 1474} css={styles.lightRatio(isDark)}>
                <Image
                  src={dashboardBgLight}
                  alt="dashboardBackground"
                  align="center"
                  css={styles.image}
                  fallback={<ImgFallbackProgress radius={imgRadius} />}
                />
              </AspectRatio>
            </Fade>
            <Fade in={isDark}>
              <AspectRatio maxH="560px" ratio={2880 / 1474} css={styles.darkRatio(isDark)}>
                <Image
                  src={dashboardBgDark}
                  alt="dashboardBackground"
                  align="center"
                  css={styles.image}
                  fallback={<ImgFallbackProgress radius={imgRadius} />}
                />
              </AspectRatio>
            </Fade>
          </Div>
        </div>
      </div>
    </Div>
  )
}

export default DashboardBg
