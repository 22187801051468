import { css, jsx } from '@emotion/react'
import { Heading } from '../chakra/Heading'
import Div from '../Div'
import theme from '../../constants/theme'

const styles = {
  heading: css`
    line-height: 1.15 !important;
    text-align: center;
  `,
  gradient: css`
    background-image: linear-gradient(90deg, ${theme.colors.custom.purple} 32.81%, ${theme.colors.custom.pink} 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  fontSizes: ['45px', '50px', '55px', '60px'],
  marginTop: ['20px', '22px', '32px', '40px'],
  badge: css`
    text-transform: uppercase;
    background-color: rgba(99, 51, 255, 0.1);
    color: ${theme.colors.accented.base};
    font-size: 12px;
    padding: 5px 18px;
    border-radius: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    cursor: default;
  `,
}

function Title() {
  return (
    <Div center mt={styles.marginTop}>
      <Div center>
      </Div>
      <Heading
        as="h1"
        fontWeight="extrabold"
        block
        fontSize={styles.fontSizes}
        css={styles.heading}
        className="not-selectable"
        color=""
      >
        All your ‘get back tos’ stored <span css={styles.gradient}>in one place</span>
      </Heading>
    </Div>
  )
}

export default Title
