import { forwardRef } from 'react'
import { AspectRatio as ChakraAspectRatio } from '@chakra-ui/react'

// https://chakra-ui.com/docs/layout/aspect-ratio

export const AspectRatio = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraAspectRatio ref={ref} {...props}>
      {props.children}
    </ChakraAspectRatio>
  )
})
