import { useColorMode } from '@chakra-ui/react'
import { css, jsx } from '@emotion/react'
import FamewallEmbed from 'react-famewall'
// import IframeResizer from 'iframe-resizer-react'
import theme from '../../constants/theme'

const styles = {
  container: css`
    position: relative;
  `,
  logoHide: isDark => css`
    background: ${isDark ? theme.colors.background.richBlack : theme.colors.background.light};
    position: absolute;
    z-index: 1000;
    bottom: 0;
    width: 100%;
    height: 60px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
  `,
}

function TestimonialFameWall() {
  const { colorMode } = useColorMode()

  return (
    <div css={styles.container}>
      <FamewallEmbed wallUrl="guardo" cardTheme={colorMode} carouselMode={false} />
      {/* <div css={styles.logoHide(colorMode === 'dark')} /> */}
    </div>
  )
}

// https://github.com/gouterz/react-famewall/blob/main/src/index.js
// function FamewallEmbed({ wallUrl, avatarCollectionId, cardTheme, carouselMode = false, dualSliderMode = false }) {
//   if (!wallUrl && !avatarCollectionId) {
//     console.error('Wall URL/ Collection Id is required')
//     return null
//   }
//
//   if (avatarCollectionId) {
//     return (
//       <IframeResizer
//         title={`Famewall Embed ${avatarCollectionId}`}
//         src={`https://wallembed.famewall.io/avatars/${avatarCollectionId}`}
//         frameBorder="0"
//         checkOrigin={false}
//         autoResize
//         scrolling="no"
//         width="100%"
//         style={{
//           border: 0,
//           width: '1px',
//           minWidth: '100%',
//         }}
//       />
//     )
//   }
//   return (
//     <IframeResizer
//       title={`Famewall Embed ${wallUrl}`}
//       src={
//         cardTheme
//           ? `https://wallembed.famewall.io/${
//               !carouselMode ? (!dualSliderMode ? 'wall' : 'slider') : 'carousel'
//             }/${wallUrl}?theme=${cardTheme}`
//           : `https://wallembed.famewall.io/${
//               !carouselMode ? (!dualSliderMode ? 'wall' : 'slider') : 'carousel'
//             }/${wallUrl}`
//       }
//       frameBorder="0"
//       checkOrigin={false}
//       autoResize
//       scrolling="no"
//       width="100%"
//       style={{
//         border: 0,
//         width: '1px',
//         minWidth: '100%',
//       }}
//     />
//   )
// }

export default TestimonialFameWall
