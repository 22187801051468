import { Fragment } from 'react'
import { css, jsx } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import Div from '../Div'
import { Text } from '../chakra/Text'
import theme from '../../constants/theme'
import VerticalSpace from '../VerticalSpace'
import BottomBg from '../../assets/svg/BottomBg.svg'
import MaxWidth from '../MaxWidth'
import { Heading } from '../chakra/Heading'
import { Button } from '../chakra/Button'
import useResponsive from '../../utils/hooks/useResponsive'
import { useIsLoggedIn } from '../../dataHooks'

const styles = {
  container: css`
    position: relative;
    color: ${theme.colors.white};
  `,
  svgWrapper: darkMode => css`
    color: ${darkMode ? theme.colors.custom.darkBlue : theme.colors.secondary.base};
  `,
  content: css`
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  `,
  contentBottom: ['0px', '0px', '0px', '40px', '30px'],
  contentTop: ['0px', '0px', '0px', '50px', '50px'],
  title: css`
    color: ${theme.colors.white};
    margin-bottom: 5px;
    strong {
      background-image: linear-gradient(90deg, ${theme.colors.custom.purple} 32.81%, ${theme.colors.custom.pink} 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: inherit;
    }
  `,
  titleFontSizes: ['25px', '30px', '34px', '38px'],
  descriptionMarginBottom: ['20px', '25px'],
  descriptionFontSizes: ['14px', '15px', '16px', '19px'],
  fakeSvg: darkMode => css`
    background-color: ${darkMode ? theme.colors.custom.darkBlue : theme.colors.secondary.base};
    width: 100%;
  `,
  fakeSvgHeight: ['220px', '220px', '200px'],
}

function BottomPanel() {
  const { colorMode } = useColorMode()
  const { isLoggedIn } = useIsLoggedIn()
  const buttonSize = useResponsive('sm', 'sm', 'md', 'md', 'lg')
  const showSvg = useResponsive(false, false, false, true)

  return (
    <Fragment>
      <VerticalSpace space={4} />
      <Div css={styles.container}>
        <Div css={styles.content} center bottom={styles.contentBottom} top={styles.contentTop}>
          <MaxWidth>
            <Heading as="h5" fontWeight="extrabold" block fontSize={styles.titleFontSizes} css={styles.title}>
              Ready for a <strong>better</strong> bookmarking experience?
            </Heading>
            <Text block fontSize={styles.descriptionFontSizes} mb={styles.descriptionMarginBottom}>
              Collect, organize, and share the best resources on the web
            </Text>
            <Button
              size={buttonSize}
              css={styles.button}
              colorScheme="accentedButton"
              lightMode
              textTransform="uppercase"
              aria-label="start now"
              to={isLoggedIn ? '/dashboard' : '/login'}
            >
              {isLoggedIn ? 'Start now' : 'Join now'}
            </Button>
          </MaxWidth>
        </Div>
        <Div css={styles.svgWrapper(colorMode === 'dark')}>
          {showSvg ? <BottomBg /> : <Div css={styles.fakeSvg(colorMode === 'dark')} height={styles.fakeSvgHeight} />}
        </Div>
      </Div>
    </Fragment>
  )
}

export default BottomPanel
