import { css } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import Icon from '../Icon'
import theme from '../../constants/theme'
import backgroundSquare from '../../assets/svg/backgroundSquare.svg'

const styles = {
  backgroundSquare: isDark => css`
    color: ${isDark ? '#443c53' : theme.colors.secondary.base};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    height: 100% !important;
    width: 100% !important;
    svg {
      height: 100% !important;
      width: 100% !important;
    }
  `,
}

function PurpleBoxBackground() {
  const { colorMode } = useColorMode()
  return <Icon icon={backgroundSquare} css={styles.backgroundSquare(colorMode === 'dark')} />
}

export default PurpleBoxBackground
