import { css, jsx } from '@emotion/react'
import { Button } from '../chakra/Button'
import Div from '../Div'
import HorizontalSpace from '../HorizontalSpace'
import VerticalSpace from '../VerticalSpace'
import useResponsive from '../../utils/hooks/useResponsive'
import scrollToView from '../../utils/scrollToView'
import theme from '../../constants/theme'

const styles = {
  buttons: css`
    transform: scale(1.15);
    width: 100%;
  `,
  width: ['220px', '150px', '180px'],
}

function ActionButtons() {
  const buttonSize = useResponsive('sm', 'sm', 'md')
  const separationSpace = useResponsive(5, 10, 12)
  const topSpace = useResponsive(6, 18, 21)
  const rowType = useResponsive('column', 'row')
  const shopExtraSpace = useResponsive(false, true)

  return (
    <Div center column={rowType === 'column'}>
      <VerticalSpace space={topSpace} />
      <Div width={styles.width} center>
        <Button
          size={buttonSize}
          css={styles.buttons}
          colorScheme="accentedButton"
          lightMode
          textTransform="uppercase"
          to="/login"
          aria-label="get started button"
        >
          Get started
        </Button>
      </Div>
      {shopExtraSpace ? <HorizontalSpace space={separationSpace} /> : <VerticalSpace space={3} />}
      <Div width={styles.width} center>
        <Button
          size={buttonSize}
          css={styles.buttons}
          color=""
          colorScheme="accentedButton"
          variant="outline"
          borderColor={theme.colors.accented.base}
          borderWidth="2px"
          textTransform="uppercase"
          onClick={() => scrollToView('Features')}
          aria-label="learn more button"
        >
          Learn more
        </Button>
      </Div>
    </Div>
  )
}

export default ActionButtons
