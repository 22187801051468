import { Fragment } from 'react'
import { css, jsx } from '@emotion/react'
import { useColorMode } from '@chakra-ui/react'
import { Box } from '../chakra/Box'
import { ExternalLink } from '../chakra/icons/ExternalLink'
import { Import } from '../chakra/icons/Import'
import { Phone } from '../chakra/icons/Phone'
import { Glasses } from '../chakra/icons/Glasses'
import { Share } from '../chakra/icons/Share'
import { FullTextSearch } from '../chakra/icons/FullTextSearch'
import { Tags } from '../chakra/icons/Tags'
import theme from '../../constants/theme'
import Div from '../Div'
import { Text } from '../chakra/Text'
import useResponsive from '../../utils/hooks/useResponsive'
import VerticalSpace from '../VerticalSpace'
import { DesktopFilled } from '../chakra/icons/DesktopFilled'
import iconSquare from '../../assets/svg/iconSquare.svg'
import Icon from '../Icon'
import PurpleBoxBackground from './PurpleBoxBackground'
import { useIsChrome } from '../../dataHooks'

const styles = {
  container: columnWidth => css`
    margin: 0 auto -50px auto;
    display: grid;
    grid-template-columns: repeat(${columnWidth}, minmax(0, 1fr));
    grid-column-gap: 1.4em;
    row-gap: 1.4em;
    align-items: start;
    justify-items: center;
    @media screen and (max-width: 425px) {
      row-gap: 0;
    }
  `,
  containerWidth: ['95%', '80%', '99%', '80%', '80%'],
  contentIconBox: css`
    padding: 17px;
    position: relative;
    background-color: transparent;
    border-radius: 8px;
    @media screen and (max-width: 425px) {
      transform: scale(0.9);
    }
  `,
  contentIconBoxSize: ['175px'],
  iconCircle: css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  `,
  iconContainerSize: ['45px'],
  title: isDark => css`
    margin-bottom: 5px;
    text-align: center;
    color: ${isDark ? '#fff' : theme.colors.secondary.base};
  `,
  titleFontSize: ['15px'],
  description: isDark => css`
    margin: auto;
    text-align: center;
    color: ${isDark ? theme.colors.text.accentedGray : 'inherit'};
  `,
  icon: isDark => css`
    color: ${isDark ? theme.colors.white : theme.colors.secondary.base};
    position: relative;
    z-index: 20;
  `,
  iconSquare: (isDark, size) => css`
    color: ${isDark ? '#443c53' : theme.colors.secondary.base};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    height: ${size}px !important;
    width: ${size}px !important;
    svg {
      height: ${size}px !important;
      width: ${size}px !important;
    }
  `,
  iconSquareNotChrome: (isDark, size) => css`
    color: ${isDark ? '#443c53' : theme.colors.secondary.base};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    height: ${size}px !important;
    width: ${size}px !important;
    box-shadow: 0 0 0 1px #bba4fd, 0 0 1px 1px #efbffd;
    border-radius: 4px;
  `,
  descriptionFontSize: ['13px'],
}

const list = [
  {
    title: 'Runs everywhere',
    description: 'Add bookmarks directly from your mobile share button.',
    icon: Phone,
    size: '20px',
  },
  { title: 'Import and export', description: 'Own your data. Get bookmarks in and out.', icon: Import, size: '20px' },
  { title: 'Share your bookmarks', description: 'Public page to share your bookmarks', icon: Share, size: '20px' },
  {
    title: 'Reading time',
    description: 'Get an estimate of content reading time.',
    icon: Glasses,
    size: '24px',
  },
  {
    title: 'Full-text search',
    description: 'Content of every web-page is fully searchable.',
    icon: FullTextSearch,
    size: '25px',
  },
  {
    title: 'Open all in browser',
    description: 'Open bookmarks in a collection all at once.',
    icon: ExternalLink,
    size: '19px',
  },
  { title: 'Organize with tags', description: 'Organize your bookmarks with custom tags.', icon: Tags, size: '20px' },
  {
    title: 'Desktop app',
    description: 'Download a chrome desktop app.',
    icon: DesktopFilled,
    size: '20px',
  },
]

function FeatureEl({ title, description, icon: IconParam, size, isDark, isChrome }) {
  return (
    <Div css={styles.contentIconBox} boxSize={styles.contentIconBoxSize}>
      <Div center mb="12px">
        <Box css={styles.iconCircle} width={styles.iconContainerSize} height={styles.iconContainerSize}>
          <IconParam width={size} height={size} css={styles.icon(isDark)} />
          {isChrome ? (
            <Icon icon={iconSquare} css={styles.iconSquare(isDark, styles.iconContainerSize)} />
          ) : (
            <span css={styles.iconSquareNotChrome(isDark, styles.iconContainerSize)} />
          )}
        </Box>
      </Div>
      <Div flex column>
        <Text fontWeight="semibold" fontSize={styles.titleFontSize} css={styles.title(isDark)}>
          {title}
        </Text>
        <Text css={styles.description(isDark)} fontSize={styles.descriptionFontSize}>
          {description}
        </Text>
      </Div>
      <PurpleBoxBackground />
    </Div>
  )
}

function FeatureList() {
  const columnWidth = useResponsive(2, 2, 4)
  const topSpace = useResponsive(4, 6)
  const botSpace = useResponsive(15)
  const { colorMode } = useColorMode()

  const isChrome = useIsChrome()

  return (
    <Fragment>
      <VerticalSpace space={topSpace} />
      <Box css={styles.container(columnWidth)} width={styles.containerWidth}>
        {list.map(item => (
          <FeatureEl key={item.title} {...item} isDark={colorMode === 'dark'} isChrome={isChrome} />
        ))}
      </Box>
      <VerticalSpace space={botSpace} />
    </Fragment>
  )
}

export default FeatureList
